import React, { useState } from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import MobileMenu from '../MobileMenu/MobileMenu'

const Layout = ({ children }) => {
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false)

  const onChangeMobileMenu = (flag) => {
    setDisplayMobileMenu(flag)
  }

  return (
    <>
      <Header  onChangeMobileMenu={onChangeMobileMenu} displayMobileMenu={displayMobileMenu}/>
      <MobileMenu displayMobileMenu={displayMobileMenu}/>
      <main>
        {children}
      </main>
      <Footer/>
    </>
  )
}

export default Layout