import React from 'react'
import './_footer.scss'

import logo from '../../images/footer/logo.svg'

import { Link } from 'gatsby'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__inner">
        <a href="https://www.ecdevstudio.com/" className="footer__logo" rel="noreferrer" target="_blank">
          <img src={logo} alt="footer logo" className="footer__logo__image"/>
        </a>
        <ul className="footer__nav">
          <li className="footer__nav__item">
            <Link
              to="/about-us"
              className="footer__nav__link"
            >
              About Us
            </Link>
          </li>
          <li className="footer__nav__item">
            <Link
              to="/contact-us"
              className="footer__nav__link"
            >
              Contact Us
            </Link>
          </li>
          <li className="footer__nav__item">
            <Link to="/calculator" className="footer__nav__link">
              Mortgage calculator
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  )
}
export default Footer