import React from 'react'
import { Link } from 'gatsby'
import headerLogo from '../../images/header/logo.svg'
import './_header.scss'

const Header = ({ onChangeMobileMenu, displayMobileMenu }) => {
  const mobMenu = false
  const burgerClass = mobMenu ? 'mobile-btn mobile-btn--active' : 'mobile-btn'
  const cls = ['header']

  return (
    <header className={cls.join(' ')}>
      <div className="header__inner">
        <Link className="header__logo" to="/">
          <img src={headerLogo} alt="logo"/>
        </Link>
        <nav className="header__nav">
          <ul className="header__nav__list">
            <li className="header__nav__item">
              <Link
                className="header__nav__link"
                to="/about-us"
              >
                About Us
              </Link>
            </li>
            <li className="header__nav__item">
              <Link
                className="header__nav__link"
                to="/contact-us"
              >
                Contact Us
              </Link>
            </li>
            <li className="header__nav__item header__nav__item-calculator">
              <Link
                className="header__nav__link header__nav__link-calculator"
                to="/calculator"
              >
                Mortgage calculator
              </Link>
            </li>
          </ul>
        </nav>
        <div className={burgerClass} onClick={() => onChangeMobileMenu(!displayMobileMenu)}
             onKeyDown={onChangeMobileMenu} role="button" tabIndex={0}>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
      </div>
    </header>
  )
}

export default Header