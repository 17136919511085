import React from 'react'
import './_mobile-menu.scss'
import { Link } from 'gatsby'

const MobileMenu = ({ displayMobileMenu }) => {
  const menuClass = displayMobileMenu ? 'mobile-menu mobile-menu--show' : 'mobile-menu'

  return (
    <div className={menuClass}>
      <ul className="mobile-menu__list">
        <li className="mobile-menu__item">
          <Link
            to="/about-us"
            className="mobile-menu__link"
          >
            About Us
          </Link>
        </li>
        <li className="mobile-menu__item">
          <Link
            to="/contact-us"
            className="mobile-menu__link"
          >
            Contact Us
          </Link>
        </li>
        <li className="mobile-menu__item">
          <Link
            to="/calculator"
            activeClassName="mobile-menu__link--active"
            className="mobile-menu__link"
          >
            Mortgage calculator
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default MobileMenu